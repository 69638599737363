import React, { Component } from "react"
import { Link } from "gatsby"
import Button from './button';
import './front-news.scss';


class FrontNews extends Component {

    renderNews = () => {
        let data = this.props.data;
        // console.log(data);
        let newsData = [];
        for (let i = 0; i < data.length; i++) {
            // console.log(data[i]);
            newsData.push(
                <div className="w-full lg:w-1/2 relative text-white xl:px-6 mt-70 lg:mt-0 md:pl-200 lg:pl-0" key={i}>
                    <Link to={data[i].node.path} className="text-white no-underline">
                        <h3 className="text-32 hide-br-mobile">
                            {data[i].node.title}
                        </h3>
                        <div className="text-standard mt-8" dangerouslySetInnerHTML={{ __html: data[i].node.acf.ingress }} />
                    </Link>
                </div>
            )
        }
        // console.log(newsData);
        return (
            <div className="w-full lg:w-2/3 flex flex-wrap xl:-mx-6 wide:-mx-50 wide:pl-200">
                {newsData}
            </div>
        );
    }
    render() {
        return (
            <div className="overflow-hidden py-150">
                <div className="flex w-full flex-wrap">
                    <div className="w-full lg:w-1/3">
                        <h2 className="title-mono text-teal">Hett just nu</h2>
                    </div>
                    {this.renderNews()}
                </div>
                <Button text="Fler nyheter" path="/hett-just-nu" animate={true}  />
            </div>
        )
    }
}

export default FrontNews
