import React, { Component } from "react"
import { Link } from "gatsby"
import feather from "feather-icons"

class Button extends Component {

    componentDidMount() {
        feather.replace()
    }

    hover() {
        if(this.props.animate && this.props.icon) {
            return(
                <div className="animate-container">
                    <i className="icon absolute"
                        width="24"
                        height="24"
                        strokeWidth="1"
                        data-feather={this.props.icon} ></i>
                </div>
            )
        } else if (!this.props.animate && this.props.icon) {
            return (
                <div className="icon-container">
                    <i className="icon absolute"
                        width="24"
                        height="24"
                        strokeWidth="1"
                        data-feather={this.props.icon} ></i>
                </div>
            )
        } else {
            return null;
        }
    }

    render() {
        if(this.props.path){
            return (
                <Link to={this.props.path} className="btn has-icon float-right relative py-3 px-1 pl-3">
                    <span className="bg-animate bg-black"></span>
                    <span className="mr-4 z-10">{this.props.text}</span>
                   {this.hover()}
                </Link>
            )
        } else {
            return (
                <button className={this.props.className + [" btn has-icon float-right relative py-3 px-1 pl-3"]} onClick={this.props.onClick} >
                    <span className={this.props.btncolor + " bg-animate"}></span>
                    <span className="mr-4 z-10">{this.props.text}</span>
                    {this.hover()}
                </button>
            )

        }
    }
}

export default Button
