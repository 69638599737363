import PropTypes from "prop-types"
import React from "react"

import Menu from "./menu"
import Logo from "./logo"


import "../../../src/assets/scss/app.scss";

const Header = (props) => (
  <header className={(props.transparent) ? 'absolute w-full' : '' }>
    <div className="px-8 py-16 flex relative h-full justify-end items-center">
      <Logo />
      <Menu />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
