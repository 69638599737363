
import React, { Component } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import './case-show.scss';
import Button from './button';


class CaseShow extends Component {

    link(data) {
        if (data) {  
            let url = '/' + data.post_type + '/' + data.post_name;
            return url ;
        }
        return '';
    }

    movieOrImage(data, index) {
        const caseData = data
        console.log(data)
        if (index === 0) {
            // Landscape mode on first of 3
            if (caseData.type_of_media === 'movie') {
                return (
                    <div className="h-full relative md:overflow-hidden">
                        <video className="video w-full absolute absolute hidden md:block" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={null}>
                            <source src={caseData.media.movie_landscape.localFile.publicURL} type="video/mp4" />
                        </video>
                        <Img fluid={caseData.media.image_landscape.localFile.childImageSharp.fluid} className="image image--one-third h-full opacity-0 hidden md:block" />

                        <video className="video w-full md:hidden" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={null}>
                            <source src={caseData.media.movie_portrait.localFile.publicURL} type="video/mp4" />
                        </video>
                        <Img fluid={caseData.media.image_portrait.localFile.childImageSharp.fluid} className="image image--one-third h-full opacity-0 md:hidden" />
                    </div>
                )
            } else if (caseData.type_of_media === 'image') {
                return (
                    <div className="h-full">
                        <Img fluid={caseData.media.image_landscape.localFile.childImageSharp.fluid} className="image h-full hidden md:block" />
                        <Img fluid={caseData.media.image_portrait.localFile.childImageSharp.fluid} className="image image--one-third h-full md:hidden" />
                    </div>
                )
            } 
        } else {
            // Portrait mode on 2 and 3 of 3
            if (caseData.type_of_media === 'movie') {
                return (
                    <div className="h-full relative md:overflow-hidden">
                        <video className="video w-full absolute absolute hidden md:block lg:hidden" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={null}>
                            <source src={caseData.media.movie_landscape.localFile.publicURL} type="video/mp4" />
                        </video>
                        <Img fluid={caseData.media.image_landscape.localFile.childImageSharp.fluid} className="image image--one-third h-full opacity-0 hidden md:block lg:hidden" />

                        <video className="video w-full md:hidden lg:block" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={null}>
                            <source src={caseData.media.movie_portrait.localFile.publicURL} type="video/mp4" />
                        </video>
                        <Img fluid={caseData.media.image_portrait.localFile.childImageSharp.fluid} className="image image--one-third h-full opacity-0 md:hidden lg:block" />
                    </div>
                )
            } else if (caseData.type_of_media === 'image') {
                return (
                    <div className="h-full">
                        <Img fluid={caseData.media.image_landscape.localFile.childImageSharp.fluid} className="image h-full hidden md:block lg:hidden" />
                        <Img fluid={caseData.media.image_portrait.localFile.childImageSharp.fluid} className="image image--one-third h-full md:hidden lg:block" />
                    </div>
                )
            } 
        }
    }

    calculateLayout(i) {
        let width;

        if (i === 0) {
            width = 'w-full pb-70 md:pb-100 lg:pb-115';
        } else {
            width = 'w-full lg:w-1/2 pb-70 md:pb-100 lg:pb-0';
        }
        
        return width;
    }

    renderCase = () => {
        return this.props.data
            .map((caseData, index) =>
                <div className={this.calculateLayout(index) + " case px-50 max-h-80 md:max-h-screen"} data-aos="fade-up" key={index}>
                    <Link to={this.link(caseData.link)} className="text-white no-underline">
                        <div className="text-white no-underline h-full relative overflow-hidden">
                            {this.movieOrImage(caseData, index)}
                            <div className="hidden lg:block case-hover w-full">
                                <div className="case-content bg-black h-full">
                                    <div className="flex flex-col w-full xl:w-3/4 justify-center h-full px-12 wide:px-16 ">
                                        <h3 className="font-mono uppercase tracking-wide text-lg mb-16" dangerouslySetInnerHTML={{ __html: caseData.client }} />
                                        <h2 className="text-6xl mb-8" dangerouslySetInnerHTML={{ __html: caseData.post_title }} />
                                        <div className="hidden lg:block font-AkkuratPro text-2xl leading-tight font-thin mb-10" dangerouslySetInnerHTML={{ __html: caseData.hover_text }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 className="title-standard mt-5 md:mt-6 lg:mt-8" dangerouslySetInnerHTML={{ __html: caseData.post_title }} />
                        <h3 className="mt-2 text-white font-thin font-AkkuratPro" dangerouslySetInnerHTML={{ __html: caseData.client }} />
                    </Link>
                </div>
            )
    }
    render() {
        console.log(this.props.data);
        return(
            <div className="overflow-hidden py-150">
                <div className="-mx-50">
                    <div className="flex w-full flex-wrap">
                        {this.renderCase()}
                    </div>
                </div>
                <Button text="Fler case" path="/case" animate={true} icon="arrow-right" />
            </div>
        )
    }
}

export default CaseShow