import React, { Component } from "react"
import { connect } from "react-redux";

class ConnectedName extends Component {
 
    randomPlaceholder() {
        let a = [
            'Kan vi hjälpa dig med <br/>något /?kompis?/?',
            'Tveka inte att <br/>höra av dig /?kompis?/!',
            'Hallå där /?kompis?/!<br/> Behöver du hjälp?',
        ]
        let rand = a[Math.floor(Math.random() * a.length)];
        return rand;
    }
    transformText(text, name) {
        text = (!text) ? 'Tveka inte höra<br/> av dig /?kompis?/!' : text;
        name = (!name) ? 'kompis' : name;
        let newText = text.replace('/\?kompis\?/', '<span class="text-yellow-lighter font-bold">' + name + '</span>');
        return (
            <h2 className="title-lg text-center" dangerouslySetInnerHTML={{ __html: newText }}></h2>
        );
    }

    render() {
        return (
            <div>
                <button className="focus:outline-none" onClick={this.props.onClick}>
                    {this.transformText(this.randomPlaceholder(), this.props.name)}
                </button>
            </div>
        )
    }
}
const mapStateToProps = function (state, props) {
    return { 
        name: state.name
    };
}
const ContactCta = connect(mapStateToProps)(ConnectedName);
export default ContactCta;