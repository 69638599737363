/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Header from "./partials/header"
import Footer from "./partials/footer"

import "../../src/assets/scss/app.scss"

const debug = true;

function log(message) {
  if (debug) {
    console.log(message);
  }
}
if (!debug) {
  console.log = function () { };
  console.warn = function () { };
}
const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
          <div className={"z-20 relative " + (props.transparent ? '' : 'bg-black') }>
            <Header transparent={props.headertransparent}/>
            {props.children}
          </div>
        <Footer fixed={props.footerfixed} />
      </div>
      )}
  />
)


export default Layout
