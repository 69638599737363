import React, { Component } from 'react';
import AutosizeInput from 'react-input-autosize';
import { connect } from "react-redux"
import { setName } from "../actions/index";
import { isBrowser } from 'react-device-detect';

function mapDispatchToProps(dispatch) {
    return {
        setName: name => dispatch(setName(name))
    };
}
function mapStateToProps(state) {
    return { name: state.name };
};
class ConnectedHejKompis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
        };
    }

    componentDidMount() {
        if(isBrowser){
            this.autofocus();
        }
    }

    autofocus() {
        const elem = document.querySelector('.autofocus-desktop');
        elem.querySelector('input').focus();
    }

    updateInputValue = (input, event) => {
        const newState = {};
        newState[input] = event.target.value;
        this.props.setName(event.target.value);
        this.setState(newState);
    };


    render() {
        return (
            <div className="text-center flex flex-col items-center justify-center text-white" style={{ height: '80vh' }}>
                <h1 className="title-lg inline">Hej&thinsp;
                 <AutosizeInput
                    className="font-AkkuratPro font-bold text-6xl inline autofocus-desktop"
                    placeholder="kompis "
                    minWidth={4}
                    value={this.state.name}
                    onChange={this.updateInputValue.bind(this, 'name')}
                    inputStyle={{ border: 'none', background: 'transparent', outline: 'none', borderRadius: 0, padding: 0, color: 'white', fontWeight: 'bold' }}
                />, <br />välkommen till Bulldozer.</h1>
                <p className="text-xlarge hide-br-mobile">Här skapas strategisk och kreativ kommunikation som får saker att hända.<br /> Rätt plats för om du också gillar feelings, förnuft och nya perspektiv.</p>
            </div>
        )
    }
}

const HejKompis = connect(mapStateToProps, mapDispatchToProps)(ConnectedHejKompis);

export default HejKompis