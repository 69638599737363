import React, { Component } from 'react';
import { Link } from "gatsby"
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

class Menu extends Component {

    constructor() {
        super();

        this.state = {
            isShowing: false
        }
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        });
        // console.log('openHandler' + this.state.isShowing);
    }

    closeModalHandler = () => {
        this.setState({
            isShowing: false
        });
        // console.log('closeHandler' + this.state.isShowing);
    }

    renderNav = () => {
        if(this.state.isShowing) {
            return(
                <div style={menuStyle} className="menu h-screen w-screen fixed pin-l pin-t z-30">
                        <div className="px-5 md:px-50 flex flex-col h-full">
                            <Link to="/tjanster" className="menu-link no-underline mt-auto">
                                <Fade delay={400}>
                                    <h2 className="title-xl">
                                        Tjänster
                                    </h2>
                                </Fade>
                            </Link>
                            <Link to="/om-bulldozer" className="menu-link no-underline mt-8">
                                <Fade delay={500}>
                                <h2 className="title-xl">
                                        Om Bulldozer
                                    </h2>
                                </Fade>
                            </Link>
                            <Link to="/hett-just-nu" className="menu-link no-underline mt-8">
                                <Fade delay={600}>
                                <h2 className="title-xl">
                                        Nyheter
                                    </h2>
                                </Fade>
                            </Link>
                            <Link to="/case" className="menu-link no-underline mt-8">
                                <Fade delay={700}>
                                    <h2 className="title-xl">
                                        Case
                                    </h2>
                                </Fade>
                            </Link>
                            <Link to="/kontakt" className="menu-link no-underline mt-8">
                                <Fade delay={800}>
                                    <h2 className="title-xl">
                                        Kontakt
                                    </h2>
                                </Fade>
                            </Link>
                        <Fade delay={900}>
                            <div className="flex text-white font-mono font-hairline uppercase text-sm md:text-base xl:text-xl leading-normal mt-auto mb-8">
                                <p className="pr-16">
                                    +46 54 40 00 350 <br />info@bulldozer.se<br /> OPEN UNTIL 5PM
                                </p>
                                <p className="pr-16">
                                    Facebook<br />Instagram <br /> linkedin
                                </p>
                                <p className="hidden md:block">
                                    <br />Integritetspolicy<br /> TERMS OF BUSINESS
                                </p>
                            </div>
                        </Fade>
                    </div>
                </div>
            )
        }
    }

    render () {
        return(
            <nav className="w-full flex justify-end items-center">
                {this.state.isShowing ? <button className=" fixed z-50 text-white text-base md:text-xl lg:text-2xl font-mono font-hairline uppercase py-4 mt-6 focus:outline-none" onClick={this.closeModalHandler}>stäng</button> : null}
                {!this.state.isShowing ? <button className="z-50 text-white absolute text-base md:text-xl lg:text-2xl font-mono font-hairline uppercase py-4 mt-6 focus:outline-none" onClick={this.openModalHandler}>Meny</button> : null}
                <Slide top when={this.state.isShowing}>
                    { this.renderNav() }
                </Slide>
            </nav>
        )
    }
}

const menuStyle = {
    background: '#4F4698',
}

export default Menu
