import React, { Component } from "react"
import axios from 'axios'

class ContactModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            fullName: '',
            phone: '',
            email: '',
            textarea: '',
            numOfSteps: 4
        }

        this._next = this._next.bind(this)
        this._prev = this._prev.bind(this)
        this.handleFormChange = this.handleFormChange.bind(this)
    }

    currentStep() {
        return this.state.currentStep
    }

    _next(e) {
        e.preventDefault();
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 1 ? currentStep + 1 : null
        this.setState({
            currentStep: currentStep,
        })
    }

    _prev(e) {
        e.preventDefault()
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    get previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <div className="inline-block">
                    <button className="link-standard text-white" onClick={ this._prev }>Tillbaka</button>
                    <span className="link-standard text-white px-4">/</span>
                </div>
            )
        }
        return null;
    }

    get nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep <= 3) {
            return (
                <button className="link-standard text-left" onClick={ this._next }>Fortsätt</button>
            )
        } else {
            return (
                <button className="link-standard text-left" onClick={ this.handleSubmit }>Skicka in</button>
            )
        }
        return null;
    }

    handleFormChange(event) {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    getStyleForStep(step) {
        let visibleStyle
        if (this.state.currentStep > step) {
            return visibleStyle = {
                transform: 'translateX(-100vw)',
                transition: 'all 0.5s ease-in-out',
                opacity: '0',
                display: 'none'
            }
        } else if (this.state.currentStep === step) {
            return visibleStyle = {
                transform: 'translateX(0vw)',
                transition: 'all 0.5s ease-in-out',
                display: 'inline-block'
            }
        } else if (this.state.currentStep < step) {
            return visibleStyle = {
                transform: 'translateX(100vw)',
                transition: 'all 0.5s ease-in-out',
                opacity: '0',
                display: 'none'
            }
        }
    }

    step1() {
        return (
            <div className="step-1 flex flex-col w-full" style={ this.getStyleForStep(1) } >
                <h2 className="title-standard pb-6">
                    We’re easy to get in touch with. New project?<br/> We would love to learn more. Tell us your name.
                </h2>
                <input onChange={ this.handleFormChange } value={ this.state.fullName } name="fullName" type="text" placeholder="Förnman och efternamn*" className="bg-transparent outline-none w-auto border-b py-2 text-large text-white w-1/2"/>
            </div>
        )
    }
 
    step2() {
        return (
            <div className="step-2 w-full flex flex-col" style={ this.getStyleForStep(2) } >
                <h2 className="title-standard pb-6">
                    Fill in your phone number
                </h2>
                <input onChange={ this.handleFormChange } value={ this.state.phone } name="phone" type="number" placeholder="Telefonnummer*" className="bg-transparent outline-none w-auto border-b py-2 text-large text-white w-1/2"/>
            </div>
        )
    }

    step3() {
        return (
            <div className="step-3 w-full flex flex-col" style={ this.getStyleForStep(3) } >
                <h2 className="title-standard pb-6">
                    Fill in your email
                </h2>
                <input onChange={ this.handleFormChange } value={ this.state.email } name="email" type="email" placeholder="E-mail*" className="bg-transparent outline-none w-auto border-b py-2 text-large text-white w-1/2"/>
            </div>
        )
    }

    step4() {
        return (
            <div className="step-4 w-full flex flex-col" style={ this.getStyleForStep(4) } >
                <h2 className="title-standard pb-6">
                    Fill in your message
                </h2>
                <textarea onChange={ this.handleFormChange } value={ this.state.textarea } name="textarea" placeholder="Message*" className="bg-transparent outline-none w-auto border p-4 text-large text-white w-1/2 h-48"></textarea>
            </div>
        )
    }

    step5() {
        return (
            <div className="step-4 w-full flex flex-col" style={ this.getStyleForStep(5) } >
                <h2 className="title-standard pb-6">
                    Tack ska du ha
                </h2>
            </div>
        )
    }

    checkForm = (formValues) => {
        return !formValues.includes("")
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const { fullName, email, textarea, phone } = this.state
        const formValues = [fullName, email, textarea, phone]
        const allGood = this.checkForm(formValues)

        if(allGood) {
            this.sendData({
                fullName: formValues[0],
                phone: formValues[3],
                email: formValues[1],
                text: formValues[2],
            })
        } else {
            alert('all no good')
        }
    }

    sendData = (data) => {
        const key = '1Y844KL3Te1GSAC6B7WkhOW5VOJ6G8gN';
        const url = 'https://forms.api.bulldozer.se/v1/app/test';

        console.log(data)

        function formatData(key, data) {
            let formatted = {};
    
            // Move props from raw data
            formatted.name = data.fullName;
            formatted.email = data.email;
            formatted.key = key;
    
            // Delete props for raw data
            delete data.fullName;
            delete data.email;
    
            // Set rest of props as fields
            formatted.fields = data;
    
            return formatted;
          }
          
        axios({
            method: 'post',
            url: url,
            data: formatData(key, data)
        }).then((response) => {
            this.setState({
                currentStep: this.state.numOfSteps + 1,
            })
        })
    }

    render() {
        return (
            <div className="overlay z-30" 
                style={{
                    transform: this.props.isOpen ? 'translateX(0vw)' : 'translateX(-100vw)',
                    opacity: this.props.isOpen ? '1' : '0',
                }} >
                <div className="bg h-screen w-screen" onClick={ this.props.handler }></div>
                <div className="modal-wrapper overflow-hidden pl-5 md:pl-50 lg:pl-70 xl:pl-24 pr-50 py-8 lg:py-16 max-h-800 min-h-500 flex justify-between flex-col absolute"
                    style={{
                        transform: this.props.isOpen ? 'translateX(0vw)' : 'translateX(-100vw)',
                    }}>
                    <div className="modal-header">
                        { (this.state.currentStep <= this.state.numOfSteps) &&
                            <span className="link-standard">{ this.currentStep() }/{ this.state.numOfSteps }</span>
                        }
                        { (this.state.currentStep >= this.state.numOfSteps) &&
                            <span className="link-standard"></span>
                        }
                        <span className="link-standard cursor-pointer" onClick={ this.props.handler }>stäng</span>
                    </div>
                    <div className="modal-body">
                        <form>
                            <article>
                                { this.step1() }
                                { this.step2() }
                                { this.step3() }
                                { this.step4() }
                                { this.step5() }
                            </article>
                        </form>
                    </div>
                    <div className="modal-footer">
                    <div className="w-full">
                        { this.previousButton }
                        { this.nextButton }
                    </div>
                    </div>
                </div>
            </div>
        ) 
    }
}

export default ContactModal