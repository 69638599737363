import React, { Component } from "react"
import { Link } from "gatsby"
import Button from './button';
import './front-news.scss';

class About extends Component {

    render() {
        let data = this.props.data;
        return (
            <div className="overflow-hidden py-150">
                <div className="">
                    <div className="flex w-full flex-wrap lg:pr-24">
                        <div className="w-full lg:w-1/3">
                            <h2 className="title-mono text-teal">{data.side_title}</h2>
                        </div>
                        <div className="w-full lg:w-2/3 md:pl-200 mt-70 lg:mt-0 wide:pr-200">
                            <h3 className="title-lg hide-br-mobile" dangerouslySetInnerHTML={{ __html: data.title}} />
                            <div className="text-standard mt-8 text-white" dangerouslySetInnerHTML={{ __html: data.text }} />
                        </div>
                    </div>
                </div>
                <Button text={data.button.title} path={data.button.url} />
            </div>
        )
    }
}

export default About
