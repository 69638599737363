import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import HejKompis from "../components/hej-kompis"
import CaseShow from "../components/case-show"
import FrontNews from "../components/front-news"
import About from "../components/about"

class IndexPage extends Component {

  render() {
    const currentPage = this.props.data.wordpressPage
    const news = this.props.data.allWordpressPost.edges
    return (
      <Layout transparent={true} className={this.props.color} footerfixed={false}>
        <SEO title="Bulldozer" keywords={[`kommunikation`, `kreativ`, `strategi`]} />
        <main className={"container px-5 "}>
          <HejKompis />
          <CaseShow data={currentPage.acf.case} />
          <FrontNews data={news} />
          <About data={currentPage.acf.about} />
        </main>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query staticFrontPage($frontpage_id: Int = 7){
    wordpressPage (wordpress_id: {eq: $frontpage_id}){
      id
      title 
      path
      acf {
        case {
          client
          project
          text
          type_of_media
          link {
            post_type
            post_name
          }
          media {
            image_landscape {
              wordpress_id
              localFile {
                childImageSharp {
                  id
                  fluid(maxWidth: 1700, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            image_portrait {
              wordpress_id
              localFile {
                childImageSharp {
                  id
                  fluid(maxWidth: 1700, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            movie_landscape {
              wordpress_id
              localFile {
                id
                publicURL
              }
            }
            movie_portrait {
              wordpress_id
              localFile {
                id
                publicURL
              }
            }
          }
        }
        about {
          side_title
          title
          text
          button {
            title
            url
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    allWordpressPost(limit: 2, sort: { fields: date, order: DESC }){
        edges {
            node {
                wordpress_id
                title
                content
                template
                slug
                excerpt
                path
                acf {
                  ingress
                }
                categories {
                    wordpress_id
                    name
                    slug
                }
            }
        }
    }
  }
  
`