import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/logo/bulldozer.svg"

class Logo extends Component {

    render() {
        return (
            <h1 style={{ margin: 0 }} className="absolute pin-x h-full flex justify-center items-center z-30">
                <Link to="/">
                    <div className="cube mt-50">
                        <div className="active-state">
                            <h2 className="uppercase text-base tracking-wide leading-normal">Bulldozer<br />Kommunikationsbyrå</h2>
                        </div>
                        <div className="default-state">
                            <img
                                className="m-0 mt-6"
                                title="Bulldozer Kommunikationsbyrå"
                                alt="Här skapas strategisk och kreativ kommunikation som får saker att hända.
                                    Rätt plats för om du också gillar feelings, förnuft och nya perspektiv."
                                src={logo} />
                        </div>
                    </div>
                </Link>
            </h1>
        )
    }
}

export default Logo
