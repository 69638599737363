import React, { Component } from 'react';
import ContactCta from "../../components/contact-cta"
import ContactModal from "../../components/contact-modal"


class Footer extends Component {
    constructor(props){
        super(props)
        this.state = {
            modalOpen: false
        }
        this.handleModal = this.handleModal.bind(this)
    }

    handleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
        // console.log(this.state.modalOpen);
    }
    render(){
        return (
            <footer className={ (this.props.footerTransparent ? 'hej' : '') + "  h-screen flex flex-col items-center w-full z-20"}> 
                <ContactModal isOpen={this.state.modalOpen} handler={this.handleModal} />
                <div className={(this.props.fixed ? 'fixed pin-b ' : '') + "footer-container px-8 w-full h-full flex flex-col items-center z-10"}>
                        <div className="mt-auto">
                            <ContactCta onClick={this.handleModal} />
                        </div>
                        <div className="flex flex-wrap w-full text-teal font-mono font-hairline uppercase text-xl py-50 leading-normal mt-auto">
                            <div className="pr-16">
                                <a href="https://www.facebook.com/bulldozerkommunikationsbyra/" rel="noopener noreferrer" target="_blank" className="text-teal font-mono font-hairline uppercase text-xl no-underline">Facebook</a><br />
                                <a href="https://www.linkedin.com/company/bulldozerkommunikationsbyra/" rel="noopener noreferrer" target="_blank" className="text-teal font-mono font-hairline uppercase text-xl no-underline">Instagram</a><br />
                                <a href="https://www.linkedin.com/company/bulldozerkommunikationsbyra/" rel="noopener noreferrer" target="_blank" className="text-teal font-mono font-hairline uppercase text-xl no-underline">linkedin</a>
                            </div>
                            <p className="pr-16">
                            <br />+46 54 40 00 350 <br />info@bulldozer.se<br />
                            </p>
                            <p>
                                <br />Integritetspolicy<br /> TERMS OF BUSINESS
                            </p>
                        </div>    
                    </div>

            </footer>
        )
    }
}

export default Footer
